<template>
  <footer>
    <nav class="navbar navbar-light bg-light iu-footer-nav" style="box-shadow: rgb(173 181 189) 0px 0px 4px 0px;">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="../assets/images/iu-sig-formal.svg" height="30" class="d-inline-block align-top" alt="">
        </a>
        © Copyright 2021
      </div>
    </nav>
  </footer>
  <!--  <footer style="padding: 5px; text-align: center;margin-top: 30px;">-->
  <!--    <div style="flex: 1;"></div>-->
  <!--    <a href="https://accessibility.iu.edu/assistance/">Accessibility</a> |-->
  <!--    <a href="#0">Privacy Notice</a> |-->
  <!--    <a href="https://www.iu.edu/copyright/index.html">Copyright</a>-->
  <!--    &copy; 2021 The Trustees of-->
  <!--    <a href="https://www.iu.edu/">Indiana University</a>-->
  <!--  </footer>-->
</template>

<script>
export default {
  name: "AppFooter"
}
</script>

<style scoped>
</style>
